import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import ReactGA from "react-ga4";
import { FiLogOut } from "react-icons/fi";
import {
	HiOutlineArrowRight,
	HiOutlineClipboard,
	HiOutlineExternalLink,
} from "react-icons/hi";
import { ImGoogle } from "react-icons/im";
import { Profile } from "~/state/jotai/profile.jotai";
import { Button } from "../button";
import { topBarThemeAtom } from "~/state/jotai";
import { useAtomValue } from "jotai";
import { useLocation } from "@remix-run/react";

const SignInDisabled: FC<{}> = () => {
	const link = useMemo(() => {
		if (typeof window != "undefined") {
			return window.location.href;
		}
		return "https://theunderground.ke";
	}, []);

	const onCopyClick = useCallback(async () => {
		await navigator.clipboard.writeText(link);
	}, [link]);

	return (
		<div className="flex gap-2 items-center justify-between rounded-lg text-sm bg-white/10 text-white/60 px-4 py-4 mb-4">
			<div>To sign in, kindly use an external browser.</div>
			<div onClick={onCopyClick}>
				<HiOutlineClipboard className="h-6 w-6 stroke-white/70" />
			</div>
		</div>
	);
};

export const SignIn: FC<
	PropsWithChildren<{
		disableSignIn: boolean;
		profile: Partial<Profile> | null;
		redirect?: string;
		className?: string;
		size?: "small" | "large";
		border?: boolean;
		cta?: boolean;
	}>
> = ({
	disableSignIn,
	profile,
	className,
	size = "large",
	border,
	children,
	redirect,
	cta,
}) => {
	const theme = useAtomValue(topBarThemeAtom);
	const onLoginButtonClick = useCallback(() => {
		if (profile) {
			ReactGA.event({
				category: "User",
				action: "Clicked Logout Link in Menu",
			});
		} else {
			ReactGA.event({
				category: "User",
				action: "Clicked Google LogIn Link in Menu",
			});
		}
	}, [profile]);

	const action = useMemo(() => {
		if (redirect) {
			return `/auth/google?redirect=${redirect}`;
		}
		return "/auth/google";
	}, [redirect]);

	if (size == "small") {
		return (
			<form
				method="post"
				action={action}
				className={`flex border-2 rounded-lg items-center border-black/80 h-full px-6 py-4 gap-4
								${profile ? "justify-between" : ""}
								${className ?? ""}`}
			>
				<button
					type={"submit"}
					disabled={disableSignIn}
					onClick={onLoginButtonClick}
					className={`disabled:opacity-40`}
				>
					{profile ? (
						<>
							<HiOutlineArrowRight className="h-6 w-6" />
						</>
					) : (
						<>
							<ImGoogle className="h-6 w-6" />
						</>
					)}
				</button>
			</form>
		);
	}
	if (children) {
		return (
			<form
				method="post"
				action={action}
				className={`w-full ${className ?? ""}`}
			>
				{disableSignIn && !profile && (
					// Add a copy link button
					<SignInDisabled />
				)}
				{children}
			</form>
		);
	}
	return (
		<form
			method="post"
			action={action}
			className={`w-full ${className ?? ""}`}
		>
			{disableSignIn && !profile && <SignInDisabled />}

			{profile ? (
				<div className="flex gap-4">
					<div
						// to={"/profile"}
						className={`flex w-full items-center whitespace-nowrap border-2 rounded-lg border-black/80 px-4 py-3 overflow-hidden 
						${theme.border}`}
					>
						<div className="flex gap-4 w-full">
							{/* <div className="overflow-hidden h-9 w-9 rounded-lg">
								<Avatar
									square
									size={36}
									name={user.sub}
									variant="marble"
									colors={[
										"#9AC9B5",
										"#25312A",
										"#94CDD0",
										"#619995",
										"#799B8C",
									]}
									// colors={[
									// 	"#9AD2D9",
									// 	"#8FBFA9",
									// 	"#B0D9B2",
									// 	"#010D00",
									// 	"#E5F2C9",
									// ]}
								/>
							</div> */}
							<div className="flex flex-col justify-center min-w-0">
								<div className="text-sm font-semibold truncate">
									{profile.firstName} {profile.lastName}
								</div>
								<div className="text-xs truncate">
									{profile.email}
								</div>
							</div>
						</div>
					</div>
					<button
						type={"submit"}
						disabled={disableSignIn}
						onClick={onLoginButtonClick}
						className={`flex items-center justify-center border-2 rounded-lg border-black/80 px-6 py-3
							${theme.border}`}
					>
						<FiLogOut className="h-6 w-6" />
					</button>
				</div>
			) : (
				<Button
					type={"submit"}
					disabled={disableSignIn}
					onClick={onLoginButtonClick}
					border={border ?? !cta}
					cta={cta}
					className={`flex items-center w-full bo px-6 py-4 gap-4 disabled:opacity-40
							${theme.border}
							${className || ""}`}
				>
					<>
						<ImGoogle className="h-6 w-6" />
						<div className="text-center whitespace-nowrap truncate">
							Sign in with Google
						</div>
					</>
				</Button>
			)}
		</form>
	);
};
